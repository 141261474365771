const clients = [
  {
    id: 2,
    system: "POC",
    module: "ZNAP_PL_PLANNING",
    modulePath: "pl",
    moduleName: "pl",
    language: "pt",
    port: 8088,
    theme: "poc.js",
    env: "prod",
  },
];

const getClientConfigs = (clientId) =>
  clients.find((client) => client.id === clientId);
const configs = getClientConfigs(2);

export default {
  ...configs,
};
